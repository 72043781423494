import {
  Component,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Input,
  Output,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core';

import { DxFormComponent } from 'devextreme-angular';
import { isNotUndefined } from '../../../core/util/functions';

@Component({
  selector: 'kt-dxform',
  templateUrl: './frm.component.html',
  styleUrls: ['./frm.component.scss'],
})
export class FrmComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild(DxFormComponent, { static: false }) myform: DxFormComponent;

  @ViewChild('frm', { static: false }) frm: DxFormComponent;

  @Input() dataForm: any = {};

  @Input() rows: string = '2';

  @Input() idForm: string = 'form';

  @Input() fields: any[] = [];

  @Input() formTitle: string = '';
  @Input() extras: any;
  @Output() formEmit: EventEmitter<any> = new EventEmitter();

  @Output() customValidationCheck: EventEmitter<any> = new EventEmitter();

  contentCheked: boolean = false;
  /* rules: Object;
	positions: string[] = [
		"HR Manager",
		"IT Manager",
		"CEO",
		"Controller",
		"Sales Manager",
		"Support Manager",
		"Shipping Manager",
	]; */

  constructor(private cdr: ChangeDetectorRef) {
    // this.employee = this.employee;
    //	this.positions = this.positions;
    //	this.rules = { X: /[02-9]/ };
  }


  ngAfterViewInit() {
    this.myform.instance.validate();
    this.myform.instance.resetOption('editorType');
    this.frm.instance.resetOption('editorType');



  }



// Função para validar a data e converter caracteres especiais para "/"
validateDateInput(event: FocusEvent) {
  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  const inputElement = event.target as HTMLInputElement;
  let input = inputElement.value;

  if (!dateRegex.test(input)) {
    alert('Formato de data inválido. Use o formato dd/mm/yyyy.');
    inputElement.value = ''; // Limpar o campo de entrada
  }
}

// Função para permitir apenas números, barras, traços, espaços e pontos
allowOnlyNumbersAndSpecialChars(event: any) {
  if (event.event) {
    event = event.event;  // Se o evento estiver dentro de um objeto maior
  }
  const inputElement = event.target as HTMLInputElement;
  inputElement.value = inputElement.value.replace(/[- .]/g, '/');
  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/', '-', ' ', '.', 'ArrowLeft', 'ArrowRight'];
  if (!allowedKeys.includes(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
    event.preventDefault();
  }
}

// Função para substituir caracteres especiais por "/"
replaceSpecialChars(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  inputElement.value = inputElement.value.replace(/[- .]/g, '/');
}



  ngAfterViewChecked() {
    this.customValidationCheck.emit();
  }

  checkUndefined(val) {
    return isNotUndefined(val);
  }

  stringify(val) {
    return JSON.stringify(val);
  }

  buttonOptions: any = {
    text: 'Register',
    type: 'success',
    useSubmitBehavior: true,
  };

  submit(event) {
    //console.log('form event', event);
    alert(1);
  }

  form_fieldDataChanged(e) {
    setTimeout(() => {
      const dxD: any = this.frm.instance.getEditor(e.dataField);
      if (dxD) {
        if(dxD.NAME==="dxDateBox") {
          dxD.option('onKeyDown', (event) => this.allowOnlyNumbersAndSpecialChars(event));
          dxD.option('displayFormat', 'dd/MM/yyyy');
        }
     }
    }, 100); // Aguarde 100 milissegundos antes de acessar o editor
  
    /*TODO - bug to select
    //e.preventDefault();
    // //console.log("mudou", e, this.dataForm);
     if (!!this.fields) {
     if(Object.prototype.hasOwnProperty.call(this.dataForm, 'type')) {
       const {type} = this.dataForm;
        let showIdSegSocCode = false;
       if(type==="Abono" || type==="Desconto") {
          showIdSegSocCode = true;  
       }
        for (const iterator of this.fields) {
        this.contentCheked =
         !!this.myform.instance.itemOption(
          iterator.dataField,
        );
        if(iterator.dataField ==="id_segsoc_code") {
          iterator.visible = true;
        this.myform.instance.itemOption(
          iterator.dataField,
          'visible',
          
          showIdSegSocCode,
        );
        
      }
      ////console.log(iterator.dataField);
        if(iterator.dataField ==="type") {
          //console.log("achou yeah", type);
        this.myform.instance.itemOption(
          iterator.dataField,
          'text',
          type
        );
        this.myform.instance.itemOption(
          iterator.dataField,
          'item',
          type
        );
           //console.log("oia", );
        }
    }
    //this.myform.itemsChange["type"].emit(type);
    //.getEditor('type').option(type);  
    
    
       //this.cdr.detectChanges();
       
     }
    	this.myform.instance.repaint();
     }
     */
  }

  onValueChanged() {
    //console.log('--------Value Change----------');
  }

  onFormSubmit = function (e) {
    //console.log('--------Submit----------');
    /* this.formEmit.emit({
			action,
			data: e,
			key: e.id,
		}); */
    // //console.log("frm submetido", e);
    // e.preventDefault();
  };

  checkCustom() {
    //console.log('--------	checkCustom ----------');
  }

 onEditorPrepared(e: any) {
  //console.log(e);
    if (e.dataType === 'date') {
      e.editorOptions = {
        ...e.editorOptions,
        onKeyDown: (event) => this.allowOnlyNumbersAndSpecialChars(event)
      };
    }
  }
onContentReady(e) {

    if (!!this.fields && !this.contentCheked) {
        let showIdSegSocCode = false;
        const { type } = this.dataForm;
        if (type === 'Abono' || type === 'Desconto') showIdSegSocCode = true;
        for (const iterator of this.fields) {
            this.contentCheked = !!this.myform.instance.itemOption(
                iterator.dataField
            );
            if (iterator.dataField === 'id_segsoc_code' || iterator.dataField ==='id_identity_type' || iterator.dataField ==='identity_type_number' || iterator.dataField ==='identity_validate') {
                iterator.visible = true;
            }

            if (iterator.editorType === 'dxSelectBox' && !iterator.lookup) {
                // Define as opções do editor (incluindo o rótulo) para dxSelectBox
                this.myform.instance.itemOption(
                    iterator.dataField,
                    {
                        label: { text: iterator.caption }, // Define o rótulo
                    }
                );
            } 

            else if (iterator.editorType === 'dxSelectBox' && iterator.lookup) {
                // Define as opções do editor (incluindo o rótulo) para dxSelectBox
                this.myform.instance.itemOption(
                    iterator.dataField,
                    {
                        label: { text: iterator.caption }, // Define o rótulo
                        editorOptions: { 
                            dataSource: iterator.lookup.dataSource.result,
                            displayExpr: iterator.lookup.displayExpr, // Define a propriedade do objeto a ser exibida
                            valueExpr: iterator.lookup.valueExpr // Define a propriedade do objeto a ser usada como valor
                        }
                    }
                );
            } 
            // Verifica se o campo é um dxDateBox
            else if (iterator.editorType === 'dxDateBox') {
                // Define as opções do editor (incluindo o rótulo) para dxDateBox

                this.myform.instance.itemOption(
                    iterator.dataField,
                    {
                        label: { text: iterator.caption }, // Define o rótulo

                    }
                );
            } 
            else {
                // Para outros tipos de campo, define apenas o rótulo
                this.myform.instance.itemOption(
                    iterator.dataField,
                    'label',
                    { text: iterator.caption }
                );
            }

            this.myform.instance.itemOption(
                iterator.dataField,
                'visible',
                iterator.visible
            );
        }
    }
  }
}