import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutService } from '../../../../core';
import { SubheaderService } from '../_services/subheader.service';
import { BreadcrumbItemModel } from '../_models/breadcrumb-item.model';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsDropdownInnerComponent } from '../../extras/dropdown-inner/notifications-dropdown-inner/notifications-dropdown-inner.component';
import { GenericService } from 'src/app/_metronic/core/services/generic.service';
import { CompanyService } from './companyService.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-subheader1',
  templateUrl: './subheader1.component.html',
  styleUrls: ['./subheader1.component.scss'],
  providers:[MatDialog]
})
export class Subheader1Component implements OnInit {

  subheaderCSSClasses = '';

  subheaderContainerCSSClasses = '';

  subheaderMobileToggle = false;

  subheaderDisplayDesc = false;

  subheaderDisplayDaterangepicker = false;

  title$: Observable<string>;

  breadcrumbs$: Observable<BreadcrumbItemModel[]>;

  breadcrumbs: BreadcrumbItemModel[] = [];

  description$: Observable<string>;

  listCompanies: any = [];
  selectedCompany: number;
   previousCompany: any;

  @Input() title: string;
  name: string = '';
  lp: KeycloakProfile;
  totNotifications: number = 0;
  constructor(
    private layout: LayoutService,
    private subheader: SubheaderService,
    private cdr: ChangeDetectorRef,
    private auth: KeycloakService,
    private service: GenericService,
    public dialog: MatDialog,
    private companyService: CompanyService,
  
  ) {
    this.title$ = this.subheader.titleSubject.asObservable();
  }
  async openNotificationsPopup() {
    if(this.totNotifications > 0) {
       await this.subheader.setReadNotifications();
       await this.getTotNot();

    }
    const dialogRef = this.dialog.open(NotificationsDropdownInnerComponent, {
      width: '400px',
      position: { top: '60px', right: '0px' }
    });
  }
  loadProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.auth.isLoggedIn()) {
        this.auth
          .loadUserProfile()
          .then((data) => resolve(data))
          .catch((error) => console.log('AUTH ERROR:', error));
      } else {
        //redirect to login
      }
    });
  }
  async ngOnInit() {

    
    await this.getCompnies();
    
    this.lp = await this.loadProfile();
    await this.getTotNot();
    this.name = this.lp.firstName;
    this.title$ = this.subheader.titleSubject.asObservable();
    this.breadcrumbs$ = this.subheader.breadCrumbsSubject.asObservable();
    this.description$ = this.subheader.descriptionSubject.asObservable();
    this.subheaderCSSClasses = this.layout.getStringCSSClasses('subheader');
    this.subheaderContainerCSSClasses = this.layout.getStringCSSClasses(
      'subheader_container'
    );
    this.subheaderMobileToggle = this.layout.getProp('subheader.mobileToggle');
    this.subheaderDisplayDesc = this.layout.getProp('subheader.displayDesc');
    this.subheaderDisplayDaterangepicker = this.layout.getProp(
      'subheader.displayDaterangepicker'
    );
    this.breadcrumbs$.subscribe((res) => {
      //console.log("AOQUIII", res);
      this.breadcrumbs = res;
      this.cdr.detectChanges();
    });
  }
  logout() {
    this.auth.logout();
  }


 onCompanyFocus(event: Event) {
    // Armazena o valor atual ao focar no select
    this.previousCompany = (event.target as HTMLSelectElement).value;
  }

onCompanyChange(event: Event) {
  const selectedValue = (event.target as HTMLSelectElement).value;
 Swal.fire({
      title: 'Alteração de Empresa',
      text: 'Tem certeza que deseja alterar de empresa?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        
        // Atualizar o localStorage com o novo valor selecionado
        localStorage.setItem('id_companies', selectedValue);
        this.companyService.setSelectedCompany(parseInt(selectedValue));
        window.location.reload();     
      }
      else {
        this.selectedCompany = this.previousCompany;
        // Ajusta o valor do select manualmente
        
        this.selectedCompany = parseInt(this.previousCompany); // Reverte o modelo
        setTimeout(() => {
          (event.target as HTMLSelectElement).value = this.previousCompany; // Reverte o DOM
        }, 0);
      }
    });
  }

  async getCompnies() {
    const data = await this.service.getData({ model: 'companies' });
    this.listCompanies = data.result;

    if (this.listCompanies.length > 0 && !localStorage.getItem('id_companies')) {
      
      localStorage.setItem("id_companies", this.listCompanies[0].id);
    }


// Obter o id_companies do localStorage
    const savedCompanyId = localStorage.getItem('id_companies');
    if (savedCompanyId) {
      this.selectedCompany = +savedCompanyId;  // Converte para número
      this.companyService.setSelectedCompany(this.selectedCompany);
      this.previousCompany = this.selectedCompany; 
    } else {
      this.selectedCompany = this.listCompanies[0].id;  // Define a primeira empresa como padrão
      this.companyService.setSelectedCompany(this.selectedCompany);
      this.previousCompany = this.selectedCompany; 
    }

    this.cdr.detectChanges();
  }
  async getTotNot() {
    const rTot:any = await this.subheader.totNotifications();
    
    if(rTot.status === 200) {
      const tot = rTot.data.result;
      if (tot.length > 0){
        this.totNotifications = tot[0].total;
       // console.log("tottt", tot)
        
      }
      else this.totNotifications = 0;
      this.cdr.detectChanges();
    }
  }
}
