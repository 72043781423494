import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../../environments/environment';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import 'devextreme/data/odata/store';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
//import { AuthService } from 'src/app/modules/auth';
//import { KeycloakService } from 'keycloak-angular';
import { AuthService } from 'src/app/modules/auth';
import { AuthGuard2 } from 'src/app/guard/auth-guard';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const DateTimeRegex =
  /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?$/;

@Injectable({
  providedIn: 'root',
})
export class AxiosService {
  auth: AuthGuard2;
  userID: number = 0;
  token: string = '';
  refreshToken: string = '';
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  resp: AxiosResponse<any>;

  ls: any;
  constructor(private router: Router, private injector: Injector) {
    this.auth = this.injector.get(AuthGuard2);
  }
  getToken(name) {
    if (this.ls) {
      const token = JSON.parse(this.ls);
      //    alert("olaaa");
      return token[`${name}`];
    }
    //  alert('dormindooo')
    return '';
  }

  axiosHTTP(): AxiosInstance {
    const axiosHTTP = axios.create({
      baseURL: '', //environment.apiUrl,
      timeout: 60000,
    });

    axiosHTTP.interceptors.request.use(
      async (config) => {
        //this.ls = localStorage.getItem(this.authLocalStorageToken);
        // if (this.ls) {
        //const token  = JSON.parse(this.ls);
        const token = await this.auth.token();
        config.headers.authorization = `bearer ${token}`; //${this.getToken('accessToken')}`;
        // }
        return config;
      },
      (error) => {
        // alert(error);
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axiosHTTP.interceptors.response.use(
      (response) => {
        ////console.log('poisss', response);
        this.resp = response;
        //  //console.log("RESP", this.resp);
        // //console.log(this.router.url);
        return response;
      },
      async (err) => {
        //  //console.log('pois', err);
        if (err.message === 'Network Error') {
          this.router.navigateByUrl('error/no-connection', {
            skipLocationChange: true,
          });
        }
        if (Object.prototype.hasOwnProperty.call(err, 'response')) {
          if (Object.prototype.hasOwnProperty.call(err.response, 'status')) {
            //console.log(err.response)
            switch (err.response.status) {
              case 401:
                const re = await this.refresh();

                if (re) {
                  // alert(this.router.url);
                  //this.router.navigateByUrl(`${this.router.url}`);
                  const currentUrl = this.router.url;
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.router.navigate([currentUrl]);
                    });
                } else {
                  notify(
                    'Erro de autenticação. Favor logar novamente',
                    'error',
                    5000
                  );
                  // localStorage.removeItem(this.authLocalStorageToken);
                  this.auth.logout();
                  // if(this.router.url !== '/auth/login') {
                  /* setTimeout(() => {
                 document.location.reload();
               }, 500);*/
                  //   }
                }
                //   this.auth.logout();
                break;
              case 400:
                // //console.log('erro', err.response);
                notify(err.response.data.message, 'error', 5000);
                /*Swal.fire({
                  title: 'Erro',
                  html: err.response.data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn font-weight-bold btn-light',
                  },
                }).then(function () {
                  //	KTUtil.scrollTop();
                }); */

                break;
              default:
                
                   notify(err, 'error', 5000);
                break;
            }
          }
        }
      }
    );
    return axiosHTTP;
  }

  async getUserID() {
    if (this.userID !== 0) {
      return this.userID;
    } else {
      const result: any = await this.allowRoles({ screenID: 'profile' });
      if (result.id > 0) {
        this.userID = result.id;
      }
    }
    return this.userID;
  }

  allowRoles(data: any) {
    return new Promise(async (solve, error) => {
      const result = await this.axiosHTTP().post(
        `${environment.apiUrl}/allowscreen`,
        data
      );
      if (result.status === 200) {
        const access = result.data.access;
        solve({ access: result.data.access, id: result.data.id });
      } else {
        error({ access: false, id: 0 });
      }
      // if(result){solve(result);} else{error(result)}
    });
  }

  async refresh() {
    /*  await this.update();
    const ls = localStorage.getItem(this.authLocalStorageToken);
    if (ls) {
      return true;
    }
    return false;*/
    return true;
  }

  async update() {
    // if(this.auth.getKeycloakInstance().isTokenExpired()) {
    /*await this.auth.updateToken(-1).then(async () => {
      this.token = await this.auth.getToken();
    });

    this.refreshToken = this.auth.getKeycloakInstance().refreshToken;
    localStorage.setItem(
      this.authLocalStorageToken,
      JSON.stringify({
        accessToken: this.token,
        refreshToken: this.refreshToken,
      })
    );*/
  }
  //}
}
