import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
 import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import * as $ from 'jquery';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
// Highlight JS
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';

import { FaceSheetModule } from './pages/workflow/expense/facesheet/face-sheet.module';

import { SharedModule } from './_metronic/shared/shared.module';
import { MessageService, ResultData } from './_metronic/core/util/MessageService';
import { AxiosService } from './_metronic/core/services/axios.service';

import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { CompanyService } from './_metronic/partials/layout/subheader/subheader1/companyService.service';

// #fake-end#

function appInitializer(authService: AuthService) {
  return () => new Promise((resolve) => {
    authService.getUserByToken().subscribe().add(resolve);
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
  //  FaceSheetModule,
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    SharedModule.forRoot(),
    OverlayModule,
    MatDialogModule,
    // #fake-start#
    /*environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],*/
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
  ],
  providers: [
  [ Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
    /*{
      
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,

      deps: [AuthService],
    },*/
    AxiosService,
    CompanyService,
    MessageService,
    
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
