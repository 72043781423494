import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root' // Isso torna o serviço disponível em toda a aplicação
})
export class CompanyService {
  private selectedCompanySource = new BehaviorSubject<number>(null); 
  selectedCompany$ = this.selectedCompanySource.asObservable(); 

  setSelectedCompany(companyId: number) {
    this.selectedCompanySource.next(companyId);
    localStorage.setItem('id_companies', companyId.toString()); 
  }

  getSelectedCompany(): number {
    const companyId = localStorage.getItem('id_companies');
    return companyId ? +companyId : null;
  }
}