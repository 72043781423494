<div class="kt-portlet">
  <div class="kt-portlet__head">
    <!--<div class="kt-portlet__head-label" id="id_form">
      <h3 class="kt-portlet__head-title text-left">{{ formTitle }}</h3>
    </div>
  </div>-->
    <div class="kt-portlet__body">
      <div id="form-container">
        <dx-form
          #frm
          [id]="idForm"
          [colCount]="rows"
          (onFieldDataChanged)="form_fieldDataChanged($event)"
          (onContentReady)="onContentReady($event)"
          (onInitialized)="onEditorPrepared($event)"
          [formData]="dataForm"
          labelLocation="left"
          [alignItemLabels]="false"
          [readOnly]="false"
          [showColonAfterLabel]="true"
          validationGroup="grp"
          [showValidationSummary]="true"
          enctype="multipart/form-data"
        >


          <!--<dxi-item
					editorType="dxSelectBox"
					caption="Robots"
					[editorOptions]="{
						dataSource: [{ id: 1, name: 'teste1' }],
						displayExpr: 'name',
						valueExpr: 'id'
					}"
				>
				</dxi-item>-->
        
        <!--<dxi-item itemType="group" *ngFor="let iterator of fields"  [caption]="iterator.caption">
             <dxi-item [dataField]="iterator.dataField">
               {{iterator.itemType}}
             </dxi-item>
        </dxi-item> -->
        </dx-form>




      </div>
    </div>
  </div>
</div>
